/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
export interface IEncodedStreamSink<T = RTCEncodedAudioFrame | RTCEncodedVideoFrame> {
    (track: MediaStreamTrack, chunk: T): boolean | PromiseLike<boolean>;
}

export default class EncodedStreamSink {
  static validateEncodedStreamSink(mode: 'audio' | 'video', item: IEncodedStreamSink) {
    if (typeof item !== 'function') {
      return {
        valid: false,
        validationResult: `Unexpected ${mode} Encoded Stream Sink function type. Found [${typeof item}]`
      };
    }

    if (item.length !== 2) {
      return {
        valid: false,
        validationResult: `Unexpected ${mode} Encoded Stream Sink function parameter length. Expected 2. Found [${item.length}]`
      };
    }

    return {
      valid: true,
      validationResult: 'ok'
    };
  }

  constructor() {
    throw new Error('EncodedStreamSink is a static class that may not be instantiated');
  }
}