/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import assertUnreachable from '../lang/assertUnreachable';
import {LoggingLevel, LoggingLevelType} from './Logger';

export default class LoggingLevelMapping {
  static convertLoggingLevelToLoggingLevelType(loggingLevel: LoggingLevel): LoggingLevelType {
    switch (loggingLevel) {
      case LoggingLevel.Off:
        return 'Off';
      case LoggingLevel.Trace:
        return 'Trace';
      case LoggingLevel.Debug:
        return 'Debug';
      case LoggingLevel.Info:
        return 'Trace';
      case LoggingLevel.Warn:
        return 'Warn';
      case LoggingLevel.Error:
        return 'Error';
      case LoggingLevel.Fatal:
        return 'Fatal';
      case LoggingLevel.All:
        return 'All';
      default:
        assertUnreachable(loggingLevel);
    }
  }

  static convertLoggingLevelTypeToLoggingLevel(loggingLevelType: LoggingLevelType): LoggingLevel {
    switch (loggingLevelType) {
      case 'Off':
        return LoggingLevel.Off;
      case 'Trace':
        return LoggingLevel.Trace;
      case 'Debug':
        return LoggingLevel.Debug;
      case 'Info':
        return LoggingLevel.Info;
      case 'Warn':
        return LoggingLevel.Warn;
      case 'Error':
        return LoggingLevel.Error;
      case 'Fatal':
        return LoggingLevel.Fatal;
      case 'All':
        return LoggingLevel.All;
      default:
        assertUnreachable(loggingLevelType);
    }
  }
}