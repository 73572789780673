/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {LoggingLevelType} from '../logger/Logger';
import {TelemetryLevelType} from '../metrics/MetricsConfiguration';

declare const __FEATURES__: {
  sendLocalCandidates: boolean;
  sendLogs: LoggingLevelType;
  sendMetrics: TelemetryLevelType;
  logToConsole: LoggingLevelType;
};

export default class BuildFeatures {
  private static _sendLocalCandidates: boolean;
  private static _sendLogs: LoggingLevelType;
  private static _sendMetrics: TelemetryLevelType;
  private static _logToConsole: LoggingLevelType;

  static get sendLocalCandidates(): boolean {
    return this._sendLocalCandidates;
  }

  static get sendLogs(): LoggingLevelType {
    return this._sendLogs;
  }

  static get sendMetrics(): TelemetryLevelType {
    return this._sendMetrics;
  }

  static get logToConsole(): LoggingLevelType {
    return this._logToConsole;
  }

  static applyFeatures(): void {
    try {
      const features = __FEATURES__;

      this._sendLocalCandidates = 'sendLocalCandidates' in features ? features.sendLocalCandidates : true;
      this._sendLogs = 'sendLogs' in features ? features.sendLogs : 'All';
      this._sendMetrics = 'sendMetrics' in features ? features.sendMetrics : 'All';
      this._logToConsole = 'logToConsole' in features ? features.logToConsole : 'All';
    } catch (e) {
      this._sendLocalCandidates = true;
      this._sendLogs = 'All';
      this._sendMetrics = 'All';
      this._logToConsole = 'All';
    }
  }
}

BuildFeatures.applyFeatures();