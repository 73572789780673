/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Disposable from '../../lang/Disposable';
import DisposableList from '../../lang/DisposableList';
import assertUnreachable from '../../lang/assertUnreachable';
import {IStreamTrackTransform} from '../transformation/StreamTrackTransform';
import {IEncodedStreamSink} from '../transformation/EncodedStreamSink';
import FeatureEnablement from '../../environment/FeatureEnablement';
import SurrogateFrameDataManager, {SurrogateFrameType, SkipFrame} from './SurrogateFrameDataManager';

type MediaStreamTrackKind = 'video' | 'audio';

export default class InsertableStreams {
  static applyEncodedStreamTransformation(stream: MediaStream, receivers: RTCRtpReceiver[], encodedVideoStreamSink: IEncodedStreamSink<RTCEncodedVideoFrame>, encodedAudioStreamSink: IEncodedStreamSink<RTCEncodedAudioFrame>, videoCodec: string, audioCodec: string): {disposables: DisposableList} {
    const disposables: DisposableList = new DisposableList();

    stream.getTracks().forEach(track => {
      const receiver = receivers.filter(receive => receive.track === track)[0];
      const kind: MediaStreamTrackKind = track.kind as MediaStreamTrackKind;

      switch (kind) {
        case 'video': {
          if (encodedVideoStreamSink) {
            if (FeatureEnablement.isRTCRtpScriptTransformSupported) {
              const worker = new Worker(new URL('./WorkerTransformStream.ts', import.meta.url), {type: 'module'});

              worker.onmessage = async event => {
                if (!await encodedVideoStreamSink(track, event.data.chunk)) {
                  worker.postMessage({forceInvalidFrame: true});
                }
              };

              receiver.transform = new RTCRtpScriptTransform(worker, {
                kind: track.kind,
                codec: videoCodec
              });
              disposables.add(new Disposable(() => {
                worker.terminate();
              }));

              break;
            }

            const encodedStreamTransformCallback: TransformerTransformCallback<RTCEncodedVideoFrame, RTCEncodedVideoFrame> = async(chunk, controller) => {
              if (!await encodedVideoStreamSink(track, chunk)) {
                let surrogate: SurrogateFrameType;

                if (chunk.type === 'key') {
                  surrogate = SurrogateFrameDataManager.getInvalidSurrogateVideoIFrame(videoCodec);
                } else {
                  surrogate = SurrogateFrameDataManager.getInvalidSurrogateVideoPFrame(videoCodec);
                }

                if (surrogate === SkipFrame) {
                  return;
                }

                chunk.data = surrogate || chunk.data;
              } else {
                if (chunk.type === 'key') {
                  chunk.data = SurrogateFrameDataManager.getSurrogateVideoIFramePerCodec(videoCodec) || chunk.data;
                } else {
                  chunk.data = SurrogateFrameDataManager.getSurrogateVideoPFramePerCodec(videoCodec) || chunk.data;
                }
              }

              controller.enqueue(chunk);
            };

            const transformer = new TransformStream({transform: encodedStreamTransformCallback});
            const receiverStreams = receiver.createEncodedStreams();
            const source = receiverStreams.readable;
            const sink = receiverStreams.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);
          }

          break;
        }

        case 'audio': {
          if (encodedAudioStreamSink) {
            if (FeatureEnablement.isRTCRtpScriptTransformSupported) {
              const worker = new Worker(new URL('./WorkerTransformStream.ts', import.meta.url), {type: 'module'});

              worker.onmessage = async event => {
                if (!await encodedAudioStreamSink(track, event.data.chunk)) {
                  worker.postMessage({forceInvalidFrame: true});
                }
              };

              receiver.transform = new RTCRtpScriptTransform(worker, {
                kind: track.kind,
                codec: audioCodec
              });

              const mediaStream = new MediaStream();
              const audioPumper = new Audio();

              audioPumper.srcObject = mediaStream;
              document.body.appendChild(audioPumper);

              disposables.add(new Disposable(() => {
                worker.terminate();
                document.body.removeChild(audioPumper);
              }));

              break;
            }

            const encodedStreamTransformCallback: TransformerTransformCallback<RTCEncodedAudioFrame, RTCEncodedAudioFrame> = async(chunk, controller) => {
              if (!await encodedAudioStreamSink(track, chunk)) {
                chunk.data = SurrogateFrameDataManager.getInvalidSurrogateAudioData();
              } else {
                chunk.data = SurrogateFrameDataManager.getSurrogateAudioSilentPerCodec(audioCodec) || chunk.data;
              }

              controller.enqueue(chunk);
            };

            const transformer = new TransformStream({transform: encodedStreamTransformCallback});
            const receiverStreams = receiver.createEncodedStreams();
            const source = receiverStreams.readable;
            const sink = receiverStreams.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);
          }

          break;
        }

        default:
          assertUnreachable(kind);
      }
    });

    return {disposables};
  }

  static applyInsertableStreamTransformation(stream: MediaStream, videoStreamTransformCallback: IStreamTrackTransform<VideoFrame>, audioStreamTransformCallback: IStreamTrackTransform<AudioData>): {transformedStream: MediaStream; disposables: DisposableList} {
    const transformedStream = new MediaStream();
    const disposables: DisposableList = new DisposableList();

    stream.getTracks().forEach(track => {
      const kind: MediaStreamTrackKind = track.kind as MediaStreamTrackKind;

      switch (kind) {
        case 'video': {
          if (videoStreamTransformCallback) {
            const insertableStreamTransformCallback: TransformerTransformCallback<VideoFrame, VideoFrame> = (chunk, controller) => {
              videoStreamTransformCallback(track, chunk, controller);
            };

            const videoTrack = track as MediaStreamVideoTrack;
            const transformer = new TransformStream({transform: insertableStreamTransformCallback});
            const processor = new MediaStreamTrackProcessor({track: videoTrack});
            const generator = new MediaStreamTrackGenerator({kind: videoTrack.kind});
            const source = processor.readable;
            const sink = generator.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);

            transformedStream.addTrack(generator);
            disposables.add(new Disposable(() => {
              transformedStream.removeTrack(generator);
            }));
          } else {
            transformedStream.addTrack(track);
          }

          break;
        }

        case 'audio': {
          if (audioStreamTransformCallback) {
            const insertableStreamTransformCallback: TransformerTransformCallback<AudioData, AudioData> = (chunk, controller) => {
              audioStreamTransformCallback(track, chunk, controller);
            };

            const audioTrack = track as MediaStreamAudioTrack;
            const transformer = new TransformStream({transform: insertableStreamTransformCallback});
            const processor = new MediaStreamTrackProcessor({track: audioTrack});
            const generator = new MediaStreamTrackGenerator({kind: audioTrack.kind});
            const source = processor.readable;
            const sink = generator.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);

            transformedStream.addTrack(generator);

            const mediaStream = new MediaStream();
            const audioPumper = new Audio();

            mediaStream.addTrack(track);
            audioPumper.srcObject = mediaStream;
            document.body.appendChild(audioPumper);
            disposables.add(new Disposable(() => {
              transformedStream.removeTrack(generator);
              document.body.removeChild(audioPumper);
            }));
          } else {
            transformedStream.addTrack(track);
          }

          break;
        }

        default:
          assertUnreachable(kind);
      }
    });

    return {
      transformedStream,
      disposables
    };
  }

  constructor() {
    throw new Error('InsertableStreams is a static class that may not be instantiated');
  }
}