/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import BrowserDetector from '../../dom/BrowserDetector';

export const SkipFrame = Symbol();

export type SurrogateFrameType = ArrayBuffer | undefined | typeof SkipFrame;

export default class SurrogateFrameDataManager {
  private static readonly _isSafari = BrowserDetector.browserName === 'Safari';
  private static readonly _isMobileChrome = BrowserDetector.browserName === 'Chrome' && BrowserDetector.isMobile();
  private static readonly _isAndroidChrome = BrowserDetector.browserName === 'Chrome' && BrowserDetector.isAndroid;
  private static readonly _singleZeroByte = new Uint8Array([0x00]);
  private static readonly _dummyAudioSilentPerCodec = {OPUS: new Uint8Array([0x68, 0x07, 0xd9, 0xab, 0xbc, 0x79, 0x87, 0xab, 0x8e, 0x4a, 0x14, 0xf3, 0xa7, 0x1c, 0xf2, 0x9a, 0x7a, 0x45, 0xa7, 0x2d, 0x44, 0x25, 0x74, 0x4c, 0xad, 0x16, 0xb3, 0x0e, 0x51, 0x57, 0xea, 0xe7, 0xad, 0x2a, 0x38, 0xce, 0x33])};
  private static readonly _dummy2x2VideoIFramePerCodec = {
    H264: new Uint8Array([
      0x00, 0x00, 0x00, 0x01, 0x09, 0xf0, // Access unit delimiter
      0x00, 0x00, 0x00, 0x01, 0x67, 0xf4, 0x00, 0x0a, 0x91, 0x9b, 0x2b, 0xf1, 0xf1, 0xf8, 0x08, 0x80, 0x00, 0x00, 0x03, 0x00, 0x80, 0x00, 0x00, 0x19, 0x07, 0x89, 0x12, 0xcb, // Sequence parameter set
      0x00, 0x00, 0x00, 0x01, 0x68, 0xeb, 0xe3, 0xc4, 0x48, 0x44, // Picture paramter set
      /* */ 0x00, 0x00, 0x01, 0x65, 0x88, 0x82, 0x00, 0x3f, 0x70, 0x23, 0x86, 0xe6, 0xd1, 0x80, 0x09, 0xaf, 0x0a, 0x41, 0x66, 0x6b, 0x54, 0x46, 0xfb, 0x71, 0xd9, 0x7f, 0xff, 0xff, 0xf9]), // Slice layer: idr data
    VP8: new Uint8Array([0xb0, 0x02, 0x00, 0x9d, 0x01, 0x2a, 0x01, 0x00, 0x01, 0x00, 0x39, 0x6b, 0x00, 0x27, 0x1c, 0x24, 0x0c, 0x2c, 0x2c, 0x44, 0xcc, 0x24, 0x41, 0xa8, 0x00, 0x13, 0xa7, 0xa3, 0x7a, 0x50, 0x00, 0xfe, 0xeb, 0xde, 0x2f, 0x8a, 0x1e, 0x6b, 0x7b, 0x07, 0x72, 0x63, 0x09, 0xa4, 0x8e, 0xc0]),
    VP9: new Uint8Array([0x82, 0x49, 0x83, 0x42, 0x00, 0x00, 0x00, 0x00, 0x06, 0x76, 0x38, 0x24, 0x1c, 0x19, 0xa0, 0x00, 0x00, 0x20, 0x40, 0x00, 0x11, 0xbf, 0xdd, 0x77, 0xff, 0x40, 0x7f, 0xb1, 0x00])
  };
  private static readonly _dummy2x2VideoPFramePerCodec = {
    H264: new Uint8Array([
      0x00, 0x00, 0x00, 0x01, 0x09, 0xf0, // Access unit delimiter
      0x00, 0x00, 0x00, 0x01, 0x41, 0x9a, 0x68, 0x49, 0xa8, 0x41, 0x68, 0x99, 0x4c, 0x0f, 0xff, 0x92, 0x81]), // Slice layer: non idr data
    VP8: new Uint8Array([0x31, 0x03, 0x00, 0xe4, 0xe0, 0xa8, 0x9b, 0x37, 0xaf, 0x10, 0x4f, 0x4f, 0xb1, 0x0a, 0x2f, 0xe4, 0x92, 0xc0, 0x1f, 0xe7, 0x4f, 0x46, 0xff, 0xee, 0x51, 0xc1, 0x5d, 0x00, 0xfe, 0xeb, 0xde, 0x2f, 0x8a, 0x1e, 0x6b, 0x7b, 0x07, 0x72, 0x63, 0x09, 0xa4, 0x8e, 0xc0]),
    VP9: new Uint8Array([0x86, 0x00, 0x40, 0x92, 0x9c, 0x24, 0x48, 0x00, 0x00, 0x03, 0x70, 0x00, 0x00, 0x4b, 0x40])
  };
  private static readonly _dummy82x82H264 = {
    IFrame: new Uint8Array([
      0x00, 0x00, 0x00, 0x01, 0x67, 0x42, 0xc0, 0x0d, 0xda, 0x18, 0xde, 0x22, 0x22, 0x10, 0x00, 0x00, 0x03, 0x00, 0x10, 0x00, 0x00, 0x03, 0x03, 0xc8, 0xf1, 0x42, 0xaa, // Sequence parameter set
      0x00, 0x00, 0x00, 0x01, 0x68, 0xce, 0x0f, 0xc8, // Picture parameter set
      0x00, 0x00, 0x00, 0x01, 0x06, 0x03, ...new Uint8Array(40).fill(0xff), 0x5f, ...new Uint8Array(10295).fill(0xff), 0x80, // Supplemental enhancement information (SEI)
      0x00, 0x00, 0x00, 0x01, 0x65, 0x88, 0x84, 0x05, ...new Uint8Array(3).fill(0xff), 0x0f, 0x45, 0x00, 0x01, 0x57, 0x9f, 0x27, 0x27, 0x27, 0x27, 0x27, 0x5d, 0x75, 0xd7, 0x5d, 0x75, 0xd7, 0x5d, 0x75, 0xd7, 0x5d, 0x75, 0xd7, 0x5d, 0x75, 0xd7, 0x5d, 0x75, 0xd7, 0x5d, 0x75, 0xd7, 0x5d, 0x78]), // Slice layer: Idr data
    PFrame: new Uint8Array([
      0x00, 0x00, 0x00, 0x01, 0x06, 0x03, ...new Uint8Array(40).fill(0xff), 0xa4, ...new Uint8Array(10364).fill(0xff), 0x80, // SEI
      0x00, 0x00, 0x00, 0x01, 0x41, 0x9a, 0x20, 0x15, 0xf0, 0x4b]) // Slice layer: non idr data
  };
  private static readonly _dummy82x82H264PFrameFrameNumberIndex = 10418;
  private static readonly _surrogate82x82PFrameFrameNumberReset = 1;
  private static readonly _useUpdateSurrogate82x82PFrameFrameNumber = SurrogateFrameDataManager._isMobileChrome || SurrogateFrameDataManager._isSafari;
  private static _surrogate82x82PFrameFrameNumber = SurrogateFrameDataManager._surrogate82x82PFrameFrameNumberReset;

  static getSurrogateAudioSilentPerCodec(codec: string): ArrayBuffer | undefined {
    switch (codec.toUpperCase()) {
      case 'OPUS': {
        return SurrogateFrameDataManager._dummyAudioSilentPerCodec.OPUS.buffer;
      }

      default: {
        return undefined;
      }
    }
  }

  static getInvalidSurrogateAudioData(): ArrayBuffer {
    return SurrogateFrameDataManager._singleZeroByte.buffer;
  }

  static getSurrogateVideoIFramePerCodec(codec: string): ArrayBuffer | undefined {
    switch (codec.toUpperCase()) {
      case 'H264': {
        if (SurrogateFrameDataManager._useUpdateSurrogate82x82PFrameFrameNumber) {
          SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber = SurrogateFrameDataManager._surrogate82x82PFrameFrameNumberReset;

          return SurrogateFrameDataManager._dummy82x82H264.IFrame.buffer;
        }

        return SurrogateFrameDataManager._dummy2x2VideoIFramePerCodec.H264.buffer;
      }

      case 'VP8': {
        return SurrogateFrameDataManager._dummy2x2VideoIFramePerCodec.VP8.buffer;
      }

      case 'VP9': {
        return SurrogateFrameDataManager._dummy2x2VideoIFramePerCodec.VP9.buffer;
      }

      default: {
        return undefined;
      }
    }
  }

  static getInvalidSurrogateVideoIFrame(codec: string): SurrogateFrameType {
    if ((codec.toUpperCase() === 'H264') && SurrogateFrameDataManager._useUpdateSurrogate82x82PFrameFrameNumber) {
      SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber = SurrogateFrameDataManager._surrogate82x82PFrameFrameNumberReset;

      if (SurrogateFrameDataManager._isAndroidChrome) {
        return SkipFrame;
      }
    }

    return SurrogateFrameDataManager._singleZeroByte.buffer;
  }

  static getSurrogateVideoPFramePerCodec(codec: string): ArrayBuffer | undefined {
    switch (codec.toUpperCase()) {
      case 'H264': {
        if (SurrogateFrameDataManager._useUpdateSurrogate82x82PFrameFrameNumber) {
          const buffer = SurrogateFrameDataManager.getSurrogate82x82PFrameAndUpdateFrameNumber();

          return buffer;
        }

        return SurrogateFrameDataManager._dummy2x2VideoPFramePerCodec.H264.buffer;
      }

      case 'VP8': {
        return SurrogateFrameDataManager._dummy2x2VideoPFramePerCodec.VP8.buffer;
      }

      case 'VP9': {
        return SurrogateFrameDataManager._dummy2x2VideoPFramePerCodec.VP9.buffer;
      }

      default: {
        return undefined;
      }
    }
  }

  static getInvalidSurrogateVideoPFrame(codec: string): SurrogateFrameType {
    if ((codec.toUpperCase() === 'H264') && SurrogateFrameDataManager._useUpdateSurrogate82x82PFrameFrameNumber) {
      const savedSurrogateFrameNumber = SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber;
      const buffer = SurrogateFrameDataManager.getSurrogate82x82PFrameAndUpdateFrameNumber();

      if (savedSurrogateFrameNumber > SurrogateFrameDataManager._surrogate82x82PFrameFrameNumberReset) {
        if (SurrogateFrameDataManager._isAndroidChrome) {
          return SkipFrame;
        }

        return SurrogateFrameDataManager._singleZeroByte.buffer;
      }

      SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber++;

      return buffer;
    }

    return SurrogateFrameDataManager._singleZeroByte.buffer;
  }

  private static getSurrogate82x82PFrameAndUpdateFrameNumber(): ArrayBuffer {
    const bitMask1 = 0x0e;
    const bitMask2 = 0x01;
    const bitShiftRightBy1 = 1;
    const bitShiftLeftBy7 = 7;
    const byte1 = ((SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber & bitMask1) >> bitShiftRightBy1);
    const byte2 = ((SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber & bitMask2) << bitShiftLeftBy7);
    const copyOfDummyPFrames = SurrogateFrameDataManager._dummy82x82H264.PFrame.slice();
    const buffer = copyOfDummyPFrames.buffer;
    const bufferIndex = SurrogateFrameDataManager._dummy82x82H264PFrameFrameNumberIndex;

    buffer[bufferIndex] |= byte1;
    buffer[bufferIndex + 1] |= byte2;
    SurrogateFrameDataManager._surrogate82x82PFrameFrameNumber++;

    return buffer;
  }

  private constructor() {
    throw new Error('SurrogateFrameDataManager is a static class that may not be instantiated');
  }
}