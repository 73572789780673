/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
export default class RtcConfigurationManager {
  static truncateIceServers(configuration: RTCConfiguration): RTCConfiguration {
    const iceServers: RTCIceServer[] = [];

    for (let i = 0; i < configuration.iceServers.length; i++) {
      const urls: string[] = [];

      for (let index = 0; index < 2; index++) {
        const url = configuration.iceServers[i].urls[index];

        if (url) {
          urls.push(configuration.iceServers[i].urls[index]);
        }
      }

      iceServers.push({
        urls: urls,
        username: configuration.iceServers[i].username,
        credential: configuration.iceServers[i].credential
      });
    }

    configuration.iceServers = iceServers;

    return configuration;
  }

  private constructor() {
    throw new Error('RtcConfigurationManager is a static class that may not be instantiated');
  }
}