/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {IAppender} from './IAppender';

export default class Appenders {
  private _appenders: Array<IAppender> = [];

  get value(): Array<IAppender> {
    return this._appenders;
  }

  add(appender: IAppender): void {
    this._appenders.push(appender);
  }

  remove(appender): void {
    this._appenders = this._appenders.reduce((items, item) => {
      if (!(item === appender)) {
        items.push(item);
      }

      return items;
    }, [] as Array<IAppender>);
  }
}