/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import PromiseHandler from './PromiseHandler';

class Promise {
  _state: number;
  _handled: boolean;
  _value: Promise;
  _deferreds: Array<PromiseHandler>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  then(onFulfilled: Function, onRejected: Function): Promise | void;

  constructor(callback) {
    if (!(this instanceof Promise)) {
      throw new TypeError('Promises must be constructed via new');
    }

    if (typeof callback !== 'function') {
      throw new TypeError('not a function');
    }

    this._state = 0;
    this._handled = false;
    this._value = undefined;
    this._deferreds = [];

    this.doResolve(callback);
  }

  doResolve(callback: Function): void {
    let done = false;

    try {
      callback(
        value => {
          if (done) {
            return;
          }

          done = true;
          this.promiseResolve(value);
        },
        reason => {
          if (done) {
            return;
          }

          done = true;
          this.promiseReject(reason);
        }
      );
    } catch (error) {
      if (done) {
        return;
      }

      done = true;
      this.promiseReject(error);
    }
  }

  promiseResolve(newValue: Promise | undefined): void {
    const promise = this as Promise;

    try {
      // Promise Resolution Procedure: https://github.com/Promises-aplus/Promises-spec#the-Promise-resolution-procedure
      if (newValue === promise) {
        throw new TypeError('A Promise cannot be resolved with it self.');
      }

      if (newValue && (typeof newValue === 'object' || typeof newValue === 'function')) {
        const then = newValue.then;

        if (newValue instanceof Promise) {
          promise._state = 3;
          promise._value = newValue;
          promise.finale();

          return;
        } else if (typeof then === 'function') {
          promise.doResolve(function(): void {
            // eslint-disable-next-line prefer-rest-params
            then.apply(newValue, arguments);
          });

          return;
        }
      }

      promise._state = 1;
      promise._value = newValue;
      promise.finale();
    } catch (e) {
      promise.promiseReject(e);
    }
  }

  promiseReject(newValue: Promise | undefined): void {
    this._state = 2;
    this._value = newValue;
    this.finale();
  }

  finale(): void {
    if (this._state === 2 && this._deferreds.length === 0) {
      Promise._immediate(() => {
        if (!this._handled) {
          Promise._unhandledRejection(this._value);
        }
      });
    }

    for (let i = 0, len = this._deferreds.length; i < len; i++) {
      this.handle(this._deferreds[i]);
    }

    this._deferreds = null;
  }

  handle(deferred: PromiseHandler): void {
    let promise = this as Promise;

    while (promise._state === 3) {
      promise = promise._value;
    }

    if (promise._state === 0) {
      promise._deferreds.push(deferred);

      return;
    }

    promise._handled = true;
    Promise._immediate(() => {
      const callback = promise._state === 1 ? deferred.onFulfilled : deferred.onRejected;

      if (callback === null) {
        if (promise._state === 1) {
          deferred.promise.promiseResolve(promise._value);
        } else {
          deferred.promise.promiseReject(promise._value);
        }

        return;
      }

      let result;

      try {
        result = callback(promise._value);
      } catch (e) {
        deferred.promise.promiseReject(e);

        return;
      }

      deferred.promise.promiseResolve(result);
    });
  }

  static all(arr: Array<Promise>): Promise {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(arr)) {
        return reject(new TypeError('Promise.all accepts an array'));
      }

      const args = Array.prototype.slice.call(arr);

      if (args.length === 0) {
        return resolve([]);
      }

      let remaining = args.length;
      const manageResponse = (i, value): void => {
        try {
          if (value && (typeof value === 'object' || typeof value === 'function')) {
            const then = value.then;

            if (typeof then === 'function') {
              then.call(
                value,
                value => manageResponse(i, value),
                reject
              );

              return;
            }
          }

          args[i] = value;

          if (--remaining === 0) {
            resolve(args);
          }
        } catch (error) {
          reject(error);
        }
      };

      for (let i = 0; i < args.length; i++) {
        manageResponse(i, args[i]);
      }
    });
  }

  static resolve(value): Promise {
    if (value && typeof value === 'object' && value.constructor === Promise) {
      return value;
    }

    return new Promise(resolve => resolve(value));
  }

  static reject(value): Promise {
    return new Promise((_, reject) => reject(value));
  }

  static race(arr: Array<Promise>): Promise {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(arr)) {
        return reject(new TypeError('Promise.race accepts an array'));
      }

      for (let i = 0, len = arr.length; i < len; i++) {
        Promise.resolve(arr[i]).then(resolve, reject);
      }
    });
  }

  static _immediate(callback: Function): void {
    const ignored = setTimeout(callback, 0);
  }

  static _unhandledRejection(error): void {
    if (typeof console !== 'undefined' && console) {
      console.warn('Possible Unhandled Promise Rejection:', error); // eslint-disable-line no-console
    }
  }
}

Promise.prototype['catch'] = function(onRejected: Function): Promise | void {
  return this.then(null, onRejected);
};

Promise.prototype['then'] = function(onFulfilled: Function, onRejected: Function): Promise | void {
  const promise = new this.constructor(() => null);

  this.handle(new PromiseHandler(onFulfilled, onRejected, promise));

  return promise;
};

Promise.prototype['finally'] = function(callback: Function): Promise | void {
  const constructor = this.constructor;

  return this.then(
    value => constructor.resolve(callback()).then(() => value),
    reason => constructor.resolve(callback()).then(() => constructor.reject(reason))
  );
};

export default Promise;