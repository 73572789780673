/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import TelemetryService from './TelemetryService';
import TelemetryConfiguration from './TelemetryConfiguration';
import {IAppender} from '../logger/IAppender';
import {LoggingLevel} from '../logger/Logger';

export default class TelemetryAppender implements IAppender {
  private readonly _telemetryService: TelemetryService;
  private readonly _tenancy: string;
  private readonly _sessionId: string;
  private readonly _threshold: LoggingLevel;

  constructor(telemetryConfiguration: TelemetryConfiguration) {
    this._tenancy = telemetryConfiguration.tenancy;
    this._sessionId = telemetryConfiguration.sessionId;
    this._threshold = telemetryConfiguration.threshold;
    this._telemetryService = new TelemetryService(telemetryConfiguration);
  }

  log(logLevel: LoggingLevel, message: string, category: string, date: Date): void {
    if (logLevel < this._threshold) {
      return;
    }

    this._telemetryService.push(logLevel, message, category, date);
  }
}