/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import IDisposable from '../../lang/IDisposable';
import ReadOnlySubject from '../../rx/ReadOnlySubject';
import Dimension from '../../video/Dimension';
import ExponentialBackoff from '../../time/ExponentialBackoff';
import EndPoint, {IStream} from '../discovery/EndPoint';
import SDK from '../SDK';
import GarbageCollectorManager from '../../dom/GarbageCollectorManager';
import IPeerConnection from '../../rtc/IPeerConnection';
import ChannelState from './ChannelState';
import assertUnreachable from '../../lang/assertUnreachable';
import Disposable from '../../lang/Disposable';
import {ILogger} from '../../logger/LoggerInterface';
import VideoTelemetry from '../../video/VideoTelemetry';
import SessionTelemetry from '../../video/SessionTelemetry';
import {IRtcMonitorStatistic} from '../../rtc/RtcConnectionMonitor';
import {BitrateMode, BitrateState} from '../api/SetTemporaryMaximalBitrate';
import EdgeAuthParser from '../../edgeAuth/EdgeAuthParser';
import {EncodedEdgeToken} from '../../edgeAuth/EncodedEdgeToken';
import {BitsPerSecond, Millisecond} from '../../units/Units';
import TokenContext from '../context/TokenContext';
import ChannelContext, {ChannelContextOptions} from '../context/ChannelContext';
import EncodedStreamSink, {IEncodedStreamSink} from '../transformation/EncodedStreamSink';
import StreamTrackTransform, {IStreamTrackTransform} from '../transformation/StreamTrackTransform';
import StreamTransformContext, {StreamTransformContextOptions} from '../context/StreamTransformContext';
import PeerConnectionContext from '../context/PeerConnectionContext';
import StateContext from '../context/StateContext';
import StreamFactory from '../streaming/StreamFactory';
import Discovery from '../discovery/Discovery';
import DiscoveryUri from '../discovery/DiscoveryUri';
import MetricsFactory from '../../metrics/MetricsFactory';
import MetricsService from '../../metrics/MetricsService';
import FeatureEnablement from '../../environment/FeatureEnablement';

const defaultStreamTerminationReason = 'client:termination';
const failureCountCleanUpIntervalInMilliseconds = 3000;
const standbyPollingIntervalInMilliseconds = 15000;

export type ChannelOptions = {
  targetLag?: Millisecond;
  videoStreamTransformCallback?: IStreamTrackTransform<VideoFrame>;
  audioStreamTransformCallback?: IStreamTrackTransform<AudioData>;
  encodedVideoStreamSink?: IEncodedStreamSink<RTCEncodedVideoFrame>;
  encodedAudioStreamSink?: IEncodedStreamSink<RTCEncodedAudioFrame>;
};

export default class Channel implements IDisposable {
  private readonly _logger: ILogger = LoggerFactory.getLogger('Channel');
  private readonly _tokenContext: TokenContext;
  private readonly _channelContext: ChannelContext;
  private readonly _peerConnectionContext: PeerConnectionContext;
  private readonly _streamTransformContext: StreamTransformContext;
  private readonly _stateContext: StateContext;
  private readonly _exponentialBackoff: ExponentialBackoff;
  private readonly _channelStartTime: number;
  private readonly _readOnlyVideoElement: ReadOnlySubject<HTMLVideoElement>;
  private readonly _readOnlyToken: ReadOnlySubject<EncodedEdgeToken>;
  private readonly _readOnlyPeerConnection: ReadOnlySubject<IPeerConnection>;
  private readonly _readOnlyState: ReadOnlySubject<ChannelState>;
  private readonly _readOnlyAutoMuted: ReadOnlySubject<boolean>;
  private readonly _readOnlyAutoPaused: ReadOnlySubject<boolean>;
  private readonly _readOnlyTokenExpiring: ReadOnlySubject<boolean>;
  private readonly _readOnlyAuthorized: ReadOnlySubject<boolean>;
  private readonly _readOnlyOnline: ReadOnlySubject<boolean>;
  private readonly _readOnlyLoading: ReadOnlySubject<boolean>;
  private readonly _readOnlyPlaying: ReadOnlySubject<boolean>;
  private readonly _readOnlyStandby: ReadOnlySubject<boolean>;
  private readonly _readOnlyStopped: ReadOnlySubject<boolean>;
  private readonly _readOnlyTargetLag: ReadOnlySubject<Millisecond>;
  private readonly _readOnlyLag: ReadOnlySubject<Millisecond>;
  private readonly _readOnlyBitrateLimit: ReadOnlySubject<BitsPerSecond>;
  private readonly _readOnlyResolution: ReadOnlySubject<Dimension>;
  private readonly _readOnlyFailureCount: ReadOnlySubject<number>;
  private readonly _readOnlyEndPoint: ReadOnlySubject<EndPoint>;
  private readonly _readOnlyStream: ReadOnlySubject<IStream>;
  private readonly _readOnlyRtcStatistics: ReadOnlySubject<IRtcMonitorStatistic>;
  private readonly _readOnlyMediaStream: ReadOnlySubject<MediaStream>;

  private _metricsService: MetricsService;
  private readonly _sessionTelemetry: SessionTelemetry;
  private readonly _videoMetaDataChangedHandler: () => void;

  constructor(videoElement: HTMLVideoElement, token: EncodedEdgeToken, options?: ChannelOptions) {
    const edgeToken = EdgeAuthParser.parseToken(token);
    const channelContextOptions: ChannelContextOptions = {targetLag: options?.targetLag};
    const streamTransformContextOptions: StreamTransformContextOptions = {
      hasInsertableStreams: edgeToken.capabilities.includes('insertable-streams'),
      hasEncodedInsertableStreams: edgeToken.capabilities.includes('encoded-insertable-streams'),
      videoStreamTransformCallback: options?.videoStreamTransformCallback,
      audioStreamTransformCallback: options?.audioStreamTransformCallback,
      encodedVideoStreamSink: options?.encodedVideoStreamSink,
      encodedAudioStreamSink: options?.encodedAudioStreamSink
    };

    this._tokenContext = new TokenContext(token);
    this._channelContext = new ChannelContext(channelContextOptions);
    this._peerConnectionContext = new PeerConnectionContext();
    this._streamTransformContext = new StreamTransformContext(streamTransformContextOptions);
    this._stateContext = new StateContext();
    this._exponentialBackoff = new ExponentialBackoff();
    this._channelStartTime = Date.now();
    this._readOnlyVideoElement = new ReadOnlySubject<HTMLVideoElement>(this._channelContext.videoElement);
    this._readOnlyToken = new ReadOnlySubject<string>(this._tokenContext.token);
    this._readOnlyPeerConnection = new ReadOnlySubject<IPeerConnection>(this._peerConnectionContext.peerConnection);
    this._readOnlyState = new ReadOnlySubject<ChannelState>(this._channelContext.state);
    this._readOnlyAutoMuted = new ReadOnlySubject<boolean>(this._channelContext.autoMuted);
    this._readOnlyAutoPaused = new ReadOnlySubject<boolean>(this._channelContext.autoPaused);
    this._readOnlyTokenExpiring = new ReadOnlySubject<boolean>(this._tokenContext.tokenExpiring);
    this._readOnlyAuthorized = new ReadOnlySubject<boolean>(this._channelContext.authorized);
    this._readOnlyOnline = new ReadOnlySubject<boolean>(this._channelContext.online);
    this._readOnlyLoading = new ReadOnlySubject<boolean>(this._channelContext.loading);
    this._readOnlyPlaying = new ReadOnlySubject<boolean>(this._channelContext.playing);
    this._readOnlyStandby = new ReadOnlySubject<boolean>(this._channelContext.standby);
    this._readOnlyStopped = new ReadOnlySubject<boolean>(this._channelContext.stopped);
    this._readOnlyTargetLag = new ReadOnlySubject<number>(this._channelContext.targetLag);
    this._readOnlyLag = new ReadOnlySubject<number>(this._channelContext.lag);
    this._readOnlyBitrateLimit = new ReadOnlySubject<number>(this._channelContext.bitrateLimit);
    this._readOnlyResolution = new ReadOnlySubject<Dimension>(this._channelContext.resolution);
    this._readOnlyFailureCount = new ReadOnlySubject<number>(this._channelContext.failureCount);
    this._readOnlyEndPoint = new ReadOnlySubject<EndPoint>(this._channelContext.endPoint);
    this._readOnlyStream = new ReadOnlySubject<IStream>(this._channelContext.stream);
    this._readOnlyRtcStatistics = new ReadOnlySubject<IRtcMonitorStatistic>(this._channelContext.rtcStatistics);
    this._readOnlyMediaStream = new ReadOnlySubject<MediaStream>(this._peerConnectionContext.mediaStream);

    const discoveryUri = (edgeToken.uri || SDK.discoveryUri.value).toString();

    SDK.tenancy.value = edgeToken.tenancy || SDK.tenancy.value;
    DiscoveryUri.uri.value = discoveryUri;
    this._metricsService = MetricsFactory.getMetricsService(discoveryUri);
    this._sessionTelemetry = new SessionTelemetry(SDK.pageLoadTime, this._metricsService);
    this._channelContext.channelDisposables.add(this._sessionTelemetry);
    this._videoMetaDataChangedHandler = this.handleVideoMetaDataChanged.bind(this);
    this.videoElement = videoElement;

    this._channelContext.channelDisposables.add(
      this._channelContext.videoElement.subscribe(videoElement => {
        this._channelContext.rendererDisposables.dispose();

        if (!videoElement) {
          return;
        }

        this._channelContext.rendererDisposables.add(this._channelContext.stream.subscribe(stream => {
          if (this._channelContext.videoTelemetry) {
            this._channelContext.videoTelemetry.dispose();
          }

          if (!stream) {
            return;
          }

          if (!this.videoElement) {
            return;
          }

          if (this.videoElement.dataset) {
            this.videoElement.dataset.sessionId = SDK.clientSessionId;
            this.videoElement.dataset.streamId = this.streamId;
          }

          this._channelContext.videoTelemetry = new VideoTelemetry(this.streamId, SDK.pageLoadTime, this._channelStartTime, this._metricsService);
          this._channelContext.videoTelemetry.setupListenerForTimeToFirstTime(this.videoElement);
          this._channelContext.videoTelemetry.setupListenerForRebuffering(this.videoElement);

          if (this._channelContext.state.value === ChannelState.Stopped) {
            const ignored = this.restartAfterStop();
          }
        }));

        this._channelContext.channelDisposables.add(this._channelContext.rendererDisposables);
      }));
    this._channelContext.channelDisposables.add(
      this._channelContext.state.subscribe(state => {
        if (this._channelContext.clearFailureCountTimeout) {
          clearTimeout(this._channelContext.clearFailureCountTimeout);
        }

        if (!this._channelContext.failureCount.value) {
          return;
        }

        if (state !== ChannelState.Playing) {
          return;
        }

        this._channelContext.clearFailureCountTimeout = window.setTimeout(() => {
          this._channelContext.failureCount.value = 0;
        }, failureCountCleanUpIntervalInMilliseconds);
      }));
    this._channelContext.channelDisposables.add(
      this._channelContext.resolution.subscribe(resolution => {
        if (this._channelContext.videoTelemetry) {
          this._channelContext.videoTelemetry.onVideoResolutionChanges(resolution.toString());
        }
      }));
    this._channelContext.channelDisposables.add(
      this._channelContext.bitrateLimit.subscribe(bitrateLimit => {
        if (bitrateLimit && this._channelContext.endPoint.value && this._channelContext.stream.value) {
          const elapsedInMilliseconds = Date.now() - this._channelContext.channelInitialization.getTime();
          const ignored = this._channelContext.endPoint.value.limitBitrate(
            this._channelContext.stream.value,
            elapsedInMilliseconds,
            bitrateLimit,
            BitrateState.Keep,
            BitrateMode.Normal
          )
            .catch(e => {
              this._logger.error('Error while setting limit bitrate', e);
            });
        }
      })
    );

    const destroyStreamOnUnmount = () => {
      if (this._channelContext.stream.value && this._channelContext.endPoint.value) {
        const ignored = this._channelContext.endPoint.value.destroyStreamOnUnmount(this._channelContext.stream.value, 'client:termination-on-window-unload');
      }
    };

    window.addEventListener('beforeunload', destroyStreamOnUnmount);

    this._channelContext.channelDisposables.add(new Disposable(() => {
      window.removeEventListener('beforeunload', destroyStreamOnUnmount);
    }));

    this.start();
  }

  get videoElement(): HTMLVideoElement {
    return this._channelContext.videoElement.value;
  }

  set videoElement(videoElement: HTMLVideoElement) {
    if (this._channelContext.videoElement.value) {
      this._channelContext.videoElement.value.removeEventListener('loadeddata', this._videoMetaDataChangedHandler);
      this._channelContext.videoElement.value.removeEventListener('loadedmetadata', this._videoMetaDataChangedHandler);
      this._channelContext.videoElement.value.removeEventListener('resize', this._videoMetaDataChangedHandler);

      if (this._channelContext.videoElement.value.dataset) {
        this._channelContext.videoElement.value.dataset.sessionId = '';
        this._channelContext.videoElement.value.dataset.streamId = '';
      }

      this._channelContext.rendererDisposables.dispose();

      this._channelContext.videoElement.value.pause();
      this._channelContext.videoElement.value.srcObject = null;
    }

    this._channelContext.autoMuted.value = false;
    this._channelContext.autoPaused.value = false;
    this._channelContext.loading.value = false;
    this._channelContext.playing.value = false;
    this._channelContext.state.value = ChannelState.Stopped;

    this._channelContext.videoElement.value = videoElement;

    if (this._channelContext.videoElement.value) {
      this._channelContext.videoElement.value.addEventListener('loadeddata', this._videoMetaDataChangedHandler);
      this._channelContext.videoElement.value.addEventListener('loadedmetadata', this._videoMetaDataChangedHandler);
      this._channelContext.videoElement.value.addEventListener('resize', this._videoMetaDataChangedHandler);
    }
  }

  private handleVideoMetaDataChanged(): void {
    const videoElement = this._channelContext.videoElement.value;

    if (videoElement) {
      if (this.resolution.value.width !== videoElement.videoWidth || this.resolution.value.height !== videoElement.videoHeight) {
        this._channelContext.resolution.value = new Dimension(videoElement.videoWidth, videoElement.videoHeight);
      }
    } else {
      this._channelContext.resolution.value = Dimension.empty;
    }
  }

  get token(): EncodedEdgeToken {
    return this._tokenContext.token.value;
  }

  set token(token: EncodedEdgeToken) {
    this._channelContext.disposables.dispose();

    this._tokenContext.token.value = token;
    this._tokenContext.tokenExpiring.value = false;

    const edgeToken = EdgeAuthParser.parseToken(this._tokenContext.token.value);
    const discoveryUri = (edgeToken.uri || SDK.discoveryUri.value).toString();

    SDK.tenancy.value = edgeToken.tenancy || SDK.tenancy.value;
    DiscoveryUri.uri.value = discoveryUri;

    this._metricsService = MetricsFactory.getMetricsService(discoveryUri);

    this._streamTransformContext.hasInsertableStreams.value = edgeToken.capabilities.includes('insertable-streams');
    this._streamTransformContext.hasEncodedInsertableStreams.value = edgeToken.capabilities.includes('encoded-insertable-streams');

    this.start();
  }

  get peerConnection(): ReadOnlySubject<IPeerConnection> {
    return this._readOnlyPeerConnection;
  }

  get state(): ReadOnlySubject<ChannelState> {
    return this._readOnlyState;
  }

  get autoMuted(): ReadOnlySubject<boolean> {
    return this._readOnlyAutoMuted;
  }

  get autoPaused(): ReadOnlySubject<boolean> {
    return this._readOnlyAutoPaused;
  }

  get tokenExpiring(): ReadOnlySubject<boolean> {
    return this._readOnlyTokenExpiring;
  }

  get authorized(): ReadOnlySubject<boolean> {
    return this._readOnlyAuthorized;
  }

  get online(): ReadOnlySubject<boolean> {
    return this._readOnlyOnline;
  }

  get loading(): ReadOnlySubject<boolean> {
    return this._readOnlyLoading;
  }

  get playing(): ReadOnlySubject<boolean> {
    return this._readOnlyPlaying;
  }

  get standby(): ReadOnlySubject<boolean> {
    return this._readOnlyStandby;
  }

  get stopped(): ReadOnlySubject<boolean> {
    return this._readOnlyStopped;
  }

  get targetLag(): ReadOnlySubject<Millisecond> {
    return this._readOnlyTargetLag;
  }

  get lag(): ReadOnlySubject<Millisecond> {
    return this._readOnlyLag;
  }

  get bitrateLimit(): number {
    return this._readOnlyBitrateLimit.value;
  }

  get resolution(): ReadOnlySubject<Dimension> {
    return this._readOnlyResolution;
  }

  get failureCount(): ReadOnlySubject<number> {
    return this._readOnlyFailureCount;
  }

  get endPoint(): ReadOnlySubject<EndPoint> {
    return this._readOnlyEndPoint;
  }

  get stream(): ReadOnlySubject<IStream> {
    return this._readOnlyStream;
  }

  get streamId(): string {
    return this._channelContext.streamId;
  }

  get rtcStatistics(): ReadOnlySubject<IRtcMonitorStatistic> {
    return this._readOnlyRtcStatistics;
  }

  get mediaStream(): ReadOnlySubject<MediaStream> {
    return this._readOnlyMediaStream;
  }

  setBitrateLimit(bitrateLimit: BitsPerSecond): void {
    this._channelContext.bitrateLimit.value = bitrateLimit;
  }

  clearBitrateLimit(): void {
    if (this._channelContext.bitrateLimit.value && this._channelContext.endPoint.value && this._channelContext.stream.value) {
      const elapsedInMilliseconds = Date.now() - this._channelContext.channelInitialization.getTime();
      const bitrateInBitsPerSecond = 0;
      const ignored = this._channelContext.endPoint.value.limitBitrate(
        this._channelContext.stream.value,
        elapsedInMilliseconds,
        bitrateInBitsPerSecond,
        BitrateState.Keep,
        BitrateMode.Reset
      )
        .then(({status}) => {
          if (status === 'ok') {
            this._channelContext.bitrateLimit.value = 0;
          }
        })
        .catch(e => {
          this._logger.error('Error while setting limit bitrate', e);
        });
    }
  }

  updateTargetLag(lag: Millisecond): void {
    this._channelContext.targetLag.value = lag;
  }

  async stop(reason: string): Promise<void> {
    return new Promise(resolve => {
      if (!this._stateContext.isStarting.value) {
        this.processStop(reason);

        resolve();

        return;
      }

      this._channelContext.rendererDisposables.add(this._stateContext.isStarting.subscribe(isStarting => {
        if (!isStarting) {
          this.processStop(reason);
          resolve();
        }
      }));
    });
  }

  private processStop(reason: string): void {
    if (this._channelContext.videoElement.value) {
      this._channelContext.videoElement.value.pause();
      this._channelContext.videoElement.value.srcObject = null;
    }

    this._channelContext.rendererDisposables.dispose();

    this.cleanUpResources(reason);

    this._channelContext.state.value = ChannelState.Stopped;
  }

  async resume(): Promise<void> {
    if (this._peerConnectionContext.mediaStream.value) {
      this._channelContext.autoPaused.value = false;

      return this.playMediaStreamInVideoElement(this._peerConnectionContext.mediaStream.value);
    }
  }

  mute(): void {
    const videoElement = this._channelContext.videoElement.value;

    if (videoElement) {
      videoElement.muted = true;
    }
  }

  unmute(): void {
    const videoElement = this._channelContext.videoElement.value;

    if (videoElement) {
      videoElement.muted = false;
      this._channelContext.autoMuted.value = false;
    }
  }

  async dispose(): Promise<void> {
    return this.stop('client:channel-dispose').then(() => {
      this._channelContext.channelDisposables.dispose();
      this._stateContext.isDisposed = true;
    });
  }

  getUri(token: EncodedEdgeToken): URL {
    const url = EdgeAuthParser.parseToken(token).uri;

    if (url) {
      return url;
    }

    this._logger.info('Fall back to the default discover URI [%s]', SDK.discoveryUri.value);

    return new URL(SDK.discoveryUri.value);
  }

  async start(): Promise<void> {
    if (this._stateContext.isDisposed) {
      throw new Error('Channel was already disposed');
    }

    if (this._stateContext.isStarting.value) {
      this._logger.info('Channel is already starting, skipping start');

      return;
    }

    this._stateContext.isStarting.value = true;

    return this.processStart();
  }

  private async processStart(): Promise<void> {
    const token: EncodedEdgeToken = this._tokenContext.token.value;
    const listenOnStreamSetup = this._sessionTelemetry.listenOnStreamSetup();

    if (!EdgeAuthParser.isEncodedEdgeTokenValid(token)) {
      this._logger.error('Failed to parse token [%s]', token);
      this._channelContext.state.value = ChannelState.Unauthorized;
      this._channelContext.authorized.value = false;
      this._stateContext.isStarting.value = false;

      return;
    }

    if (this._streamTransformContext.videoStreamTransformCallback) {
      const {valid, validationResult} = StreamTrackTransform.validateMediaStreamTrackTransform('video', this._streamTransformContext.videoStreamTransformCallback);

      if (!valid) {
        this._logger.error(validationResult);
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    }

    if (this._streamTransformContext.audioStreamTransformCallback) {
      const {valid, validationResult} = StreamTrackTransform.validateMediaStreamTrackTransform('audio', this._streamTransformContext.audioStreamTransformCallback);

      if (!valid) {
        this._logger.error(validationResult);
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    }

    if (this._streamTransformContext.encodedVideoStreamSink) {
      const {valid, validationResult} = EncodedStreamSink.validateEncodedStreamSink('video', this._streamTransformContext.encodedVideoStreamSink);

      if (!valid) {
        this._logger.error(validationResult);
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    }

    if (this._streamTransformContext.encodedAudioStreamSink) {
      const {valid, validationResult} = EncodedStreamSink.validateEncodedStreamSink('audio', this._streamTransformContext.encodedAudioStreamSink);

      if (!valid) {
        this._logger.error(validationResult);
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    }

    if (this._streamTransformContext.hasInsertableStreams.value && this._streamTransformContext.hasEncodedInsertableStreams.value) {
      this._logger.error('Both insertable-streams and encoded-insertable-streams are enabled, only use one or the other');
      this._channelContext.state.value = ChannelState.ConfigurationError;
      this._channelContext.authorized.value = false;
      this._stateContext.isStarting.value = false;

      return;
    }

    const hasTransformStreamCallback = typeof this._streamTransformContext.videoStreamTransformCallback === 'function' || typeof this._streamTransformContext.audioStreamTransformCallback === 'function';
    const hasEncodedStreamSink = typeof this._streamTransformContext.encodedVideoStreamSink === 'function' || typeof this._streamTransformContext.encodedAudioStreamSink === 'function';

    if (hasTransformStreamCallback && hasEncodedStreamSink) {
      this._logger.error('Both Media Stream Track transform callback and encodedInsertableStreams sink function found, only use one type or the other');
      this._channelContext.state.value = ChannelState.ConfigurationError;
      this._channelContext.authorized.value = false;
      this._stateContext.isStarting.value = false;

      return;
    }

    if (this._streamTransformContext.hasInsertableStreams.value) {
      if (!FeatureEnablement.isInsertableStreamsSupported) {
        this._logger.error('Browser does not support Media Stream Track API');
        this._channelContext.state.value = ChannelState.UnsupportedFeature;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }

      if (!hasTransformStreamCallback) {
        this._logger.error('CreateChannelOptions transform callback function is missing; however, insertable-streams is enabled');
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    } else {
      if (hasTransformStreamCallback) {
        this._logger.error(`CreateChannelOptions transform callback function found; however, insertable-streams is not enabled`);
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    }

    if (this._streamTransformContext.hasEncodedInsertableStreams.value) {
      if (!(FeatureEnablement.isEncodedInsertableStreamsSupported || FeatureEnablement.isRTCRtpScriptTransformSupported)) {
        this._logger.error('Browser does not support encodedInsertableStreams API');
        this._channelContext.state.value = ChannelState.UnsupportedFeature;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }

      if (!hasEncodedStreamSink) {
        this._logger.error('CreateChannelOptions transform sink function is missing; however, encoded-insertable-streams is enabled');
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    } else {
      if (hasEncodedStreamSink) {
        this._logger.error(`CreateChannelOptions transform sink function found; however, encoded-insertable-streams is not enabled`);
        this._channelContext.state.value = ChannelState.ConfigurationError;
        this._channelContext.authorized.value = false;
        this._stateContext.isStarting.value = false;

        return;
      }
    }

    this.cleanUpResources('client:start');
    this._channelContext.state.value = ChannelState.Starting;
    this._channelContext.loading.value = true;

    const uri = this.getUri(token);

    this._channelContext.disposables.add(
      this._channelContext.state.subscribe(state => {
        if (state === ChannelState.Error || state === ChannelState.Recovering) {
          Discovery.clearCachedClosestEndpoint(uri);
        }
      })
    );

    const handleStreamFailureCallback: () => Promise<void> = () => new Promise((resolve): void => {
      // Need to set isStarting to false and call handleStreamFailure if stream monitors found an issue
      this._stateContext.isStarting.value = false;

      return resolve(this.handleStreamFailure());
    });
    const streamPlayer = StreamFactory.create(token, this._channelContext, this._peerConnectionContext, this._streamTransformContext, handleStreamFailureCallback);

    if (!streamPlayer) {
      this._stateContext.isStarting.value = false;

      return;
    }

    return streamPlayer.start(
      uri,
      token,
      listenOnStreamSetup,
      this.playMediaStreamInVideoElement.bind(this))
      .then(() => {
        this._channelContext.loading.value = false;
      })
      .catch(e => {
        listenOnStreamSetup.fail();

        this._channelContext.failureCount.value++;

        this._channelContext.online.value = false;

        this.cleanUpResources('client:cleanup-after-failed-setup');

        this._channelContext.state.value = ChannelState.ClientStartError;

        this._logger.error('Failed to start channel', e);
      })
      .finally(() => {
        this._stateContext.isStarting.value = false;

        if (this._channelContext.state.value === ChannelState.Playing || !SDK.automaticRetryOnFailure) {
          return;
        }

        const timeoutId = setTimeout(() => {
          const ignored = this.handleStreamFailure()
            .catch(e => {
              this._logger.error('Failed handling stream failure', e);
            });
        }, this.getRetryInterval());

        this._channelContext.disposables.add(new Disposable(() => {
          clearTimeout(timeoutId);
        }));
      });
  }

  private async restartAfterStop(): Promise<void> {
    if (this._stateContext.isDisposed) {
      throw new Error('Channel was already disposed');
    }

    if (this._peerConnectionContext.mediaStream.value) {
      return this.playMediaStreamInVideoElement(this._peerConnectionContext.mediaStream.value);
    }

    if (this._tokenContext.token.value) {
      const ignored = this.start();
    }
  }

  public async play(): Promise<void> {
    const mediaStream = this._peerConnectionContext.mediaStream.value;

    if (!mediaStream) {
      return this.start();
    }

    return this.playMediaStreamInVideoElement(mediaStream);
  }

  private getRetryInterval(): number {
    switch (this._channelContext.state.value) {
      case ChannelState.StandBy:
      case ChannelState.Offline:
        return standbyPollingIntervalInMilliseconds;
      case ChannelState.Error:
      case ChannelState.Recovering:
      case ChannelState.Unauthorized:
      case ChannelState.GeoRestricted:
      case ChannelState.GeoBlocked:
      case ChannelState.Stopped:
      case ChannelState.Starting:
      case ChannelState.Playing:
      case ChannelState.Paused:
      case ChannelState.Reconnecting:
      case ChannelState.UnsupportedFeature:
      case ChannelState.ConfigurationError:
      case ChannelState.TransientConfigurationError:
      case ChannelState.ConnectionError:
      case ChannelState.ClientStartError:
        // First and second attempt fast, after that exponential with backoff interval
        return this._exponentialBackoff.getExponentialBackoffIntervalByFailureCount(this._channelContext.failureCount.value);
      default:
        assertUnreachable(this._channelContext.state.value);
    }
  }

  private async handleStreamFailure(): Promise<void> {
    switch (this._channelContext.state.value) {
      case ChannelState.Error:
      case ChannelState.Reconnecting:
      case ChannelState.StandBy:
      case ChannelState.Offline:
      case ChannelState.Recovering:
      case ChannelState.TransientConfigurationError:
      case ChannelState.ConnectionError:
      case ChannelState.ClientStartError:
        this._logger.info('Retry start with initial state [%s] [%s]', this._channelContext.state.value, ChannelState[this._channelContext.state.value]);

        break;
      case ChannelState.Unauthorized:
        this._logger.info('Channel is unauthorized, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.GeoRestricted:
        this._logger.info('Channel is geo restricted, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.GeoBlocked:
        this._logger.info('Channel is geo blocked, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.Stopped:
        this._logger.info('Channel is stopped, skipping retry of start.');

        return;
      case ChannelState.Playing:
        this._logger.info('Channel is playing, skipping retry of start');

        return;
      case ChannelState.Paused:
        this._logger.info('Channel is paused, skipping retry of start. Please invoke play()');

        return;
      case ChannelState.Starting:
        this._logger.info('Channel is already starting, skipping retry of start');

        return;
      case ChannelState.UnsupportedFeature:
        this._logger.info('Channel is stopped due to unsupported feature, skipping retry of start.');

        return;
      case ChannelState.ConfigurationError:
        this._logger.info('Channel is stopped due to configuration error, skipping retry of start.');

        return;
      default:
        assertUnreachable(this._channelContext.state.value);
    }

    return this.start();
  }

  private cleanUpResources(reason: string = defaultStreamTerminationReason): void {
    this._channelContext.disposables.dispose();

    const peerConnection = this._peerConnectionContext.peerConnection.value;

    if (peerConnection) {
      this._peerConnectionContext.peerConnection.value = null;
      peerConnection.close();
      peerConnection.dispose();

      if (SDK.forceGarbageCollectionOnRestart && !(FeatureEnablement.isMobile && SDK.skipGarbageCollectionOnMobileDevices)) {
        GarbageCollectorManager.forceGarbageCollection();
      }
    }

    if (this._peerConnectionContext.mediaStream.value) {
      this._peerConnectionContext.mediaStream.value.getTracks().forEach(track => track.stop());
      this._peerConnectionContext.mediaStream.value = null;
    }

    this._channelContext.autoPaused.value = false;
    this._channelContext.autoMuted.value = false;
    this._channelContext.playing.value = false;
    this._channelContext.stopped.value = true;
    this._channelContext.standby.value = false;

    if (this._channelContext.stream.value && this._channelContext.endPoint.value) {
      const ignored = this._channelContext.endPoint.value.destroyStream(this._channelContext.stream.value, reason)
        .then(({status}) => {
          if (status !== 'ok') {
            this._logger.warn('[%s] Failed to destroy stream with reason [%s]', this.streamId, status);

            return;
          }

          this._logger.info('[%s] Destroyed stream with reason [%s]', this.streamId, status);
        })
        .catch(e => {
          this._logger.error('[%s] Failed to destroy stream', this.streamId, e);
        });
    }

    if (this.videoElement && this.videoElement.dataset) {
      this.videoElement.dataset.sessionId = '';
      this.videoElement.dataset.streamId = '';
    }

    this._channelContext.stream.value = null;
    this._channelContext.endPoint.value = null;
    this._peerConnectionContext.peerConnectionReconnectAttempts = 0;
  }

  private async playMediaStreamInVideoElement(mediaStream: MediaStream): Promise<void> {
    const videoElement = this._channelContext.videoElement.value;

    if (!videoElement) {
      this._channelContext.autoMuted.value = false;
      this._channelContext.autoPaused.value = false;
      this._channelContext.loading.value = false;
      this._channelContext.playing.value = false;
      this._channelContext.state.value = ChannelState.Stopped;

      return;
    }

    if (!this._streamTransformContext.hasEncodedInsertableStreams.value) {
      videoElement.srcObject = mediaStream;
    }

    const playPromise = videoElement.play();

    if (playPromise === undefined) {
      this._channelContext.autoMuted.value = false;
      this._channelContext.autoPaused.value = false;
      this._channelContext.loading.value = false;
      this._channelContext.playing.value = true;
      this._channelContext.state.value = ChannelState.Playing;

      return;
    }

    return playPromise.then(() => {
      this._channelContext.autoMuted.value = false;
      this._channelContext.autoPaused.value = false;
      this._channelContext.loading.value = false;
      this._channelContext.playing.value = true;
      this._channelContext.state.value = ChannelState.Playing;
    }).catch(e => {
      const hasAudioTrack = !!mediaStream.getTracks().filter(track => {
        return track.kind === 'audio';
      }).length;
      const automaticallyMuteVideoOnPlayFailureOff = !SDK.automaticallyMuteVideoOnPlayFailure;

      if (automaticallyMuteVideoOnPlayFailureOff || videoElement.muted || !hasAudioTrack) {
        videoElement.srcObject = null;
        this._channelContext.autoMuted.value = false;
        this._channelContext.autoPaused.value = true;
        this._channelContext.loading.value = false;
        this._channelContext.playing.value = false;
        this._channelContext.state.value = ChannelState.Paused;

        if (automaticallyMuteVideoOnPlayFailureOff) {
          this._logger.info('[%s] Paused video after play failed. Manual user action required.', this.streamId, e);

          return;
        }

        if (hasAudioTrack) {
          this._logger.info('[%s] Failed to play video. Manual user action required.', this.streamId, e);

          return;
        }

        this._logger.info('[%s] Failed to play muted video. Manual user action required.', this.streamId, e);

        return;
      }

      videoElement.muted = true;

      return videoElement.play()
        .then(() => {
          this._logger.info('[%s] Played video after auto muting. Manual user action required to unmute.', this.streamId);

          this._channelContext.autoMuted.value = true;
          this._channelContext.autoPaused.value = false;
          this._channelContext.loading.value = false;
          this._channelContext.playing.value = true;
          this._channelContext.state.value = ChannelState.Playing;
        }).catch(e => {
          videoElement.muted = false;

          this._logger.info('[%s] Failed to play video. Manual user action required.', this.streamId, e);

          videoElement.srcObject = null;
          this._channelContext.autoMuted.value = false;
          this._channelContext.autoPaused.value = true;
          this._channelContext.loading.value = false;
          this._channelContext.playing.value = false;
          this._channelContext.state.value = ChannelState.Paused;
        });
    });
  }
}