/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import IPeerConnectionFactory from './IPeerConnectionFactory';
import IPeerConnection from './IPeerConnection';
import VanillaPeerConnection from './VanillaPeerConnection';

class VanillaPeerConnectionFactory implements IPeerConnectionFactory {
  async createPeerConnection(configuration?: RTCConfiguration): Promise<IPeerConnection> {
    return new VanillaPeerConnection(configuration);
  }
}

export default VanillaPeerConnectionFactory;