/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {LoggingLevel} from '../logger/Logger';
import LoggerDefaults from '../logger/LoggerDefaults';

export default class TelemetryConfiguration {
  private _url = 'https://telemetry.phenixrts.com/telemetry/logs';
  private _tenancy: string;
  private _sessionId: string;
  private _environment: string;
  private _pageLoadTime: number;
  private _threshold = LoggerDefaults.defaultTelemetryLoggingLevel;

  get url(): string {
    return this._url;
  }

  set url(url: string) {
    const telemetryUrl = new URL(url);

    telemetryUrl.pathname = telemetryUrl.pathname + '/logs';

    this._url = telemetryUrl.toString();
  }

  get environment(): string {
    return this._environment;
  }

  set environment(environment: string) {
    this._environment = environment;
  }

  get tenancy(): string {
    return this._tenancy;
  }

  set tenancy(tenancy: string) {
    this._tenancy = tenancy;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  get pageLoadTime(): number {
    return this._pageLoadTime;
  }

  set pageLoadTime(pageLoadTime: number) {
    this._pageLoadTime = pageLoadTime;
  }

  get threshold(): LoggingLevel {
    return this._threshold;
  }

  set threshold(threshold: LoggingLevel) {
    this._threshold = threshold;
  }
}