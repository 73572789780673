/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import BuildFeatures from '../environment/BuildFeatures';
import {LoggingLevel} from '../logger/Logger';

export default class LoggerDefaults {
  static get defaultLoggingLevel(): LoggingLevel {
    return LoggingLevel[BuildFeatures.sendLogs];
  }

  static get defaultConsoleLoggingLevel(): LoggingLevel {
    return LoggingLevel[BuildFeatures.logToConsole];
  }

  static get defaultTelemetryLoggingLevel(): LoggingLevel {
    return LoggingLevel.Info;
  }
}