/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import IPeerConnection from './IPeerConnection';
import Subject from '../rx/Subject';
import FeatureEnablement from '../environment/FeatureEnablement';
import SdpParser from './SdpParser';
import IDisposable from '../lang/IDisposable';

export interface IRtcMonitorStatistic {
  [kind: string]: IRtcStatistic;
}

export interface ILegacyRTCStatsReport {
  result: () => ILegacyRTCStatsReportItem[];
}

export interface ILegacyRTCStatsReportItem extends RTCStatsReport {
  type: string;
  names: () => string[];
  stat: (name) => number;
}

export interface IRtcStatistic {
  ssrc?: string;
  mediaType?: string;
  timestamp: number;
  bitrate?: number;
  bytesReceived?: number;
  framesDecoded?: number;
  packetsLost?: number;
  packetsReceived?: number;
  codec?: string;
  fps?: number;
  roundTripTime?: number;
  lastPacketReceivedTimestamp?: number;
}

const updateStatisticTimeout = 1000;

export default class RtcConnectionMonitor implements IDisposable {
  private readonly _estimatedRoundTripTime: number;
  private readonly _estimatedVideoCodec: string;
  private readonly _estimatedAudioCodec: string;
  private readonly _rtcStatistic: Subject<IRtcMonitorStatistic> = new Subject<IRtcMonitorStatistic>({});
  private _peerConnection: IPeerConnection;
  private _isMonitorRunning = true;
  private _updateTimeout: number;
  private _tracksToMonitor: string[];

  constructor(peerConnection: IPeerConnection, mediaStream: MediaStream, estimatedRoundTripTime: number) {
    this._peerConnection = peerConnection;
    this._estimatedRoundTripTime = estimatedRoundTripTime;
    this._tracksToMonitor = mediaStream.getTracks().map(track => track.kind) || [];

    if (this._peerConnection) {
      if (!FeatureEnablement.getCurrentOfferDisabled) {
        const parsedSDP = new SdpParser(this._peerConnection.currentRemoteDescription.sdp);

        this._estimatedAudioCodec = parsedSDP.audioCodec;
        this._estimatedVideoCodec = parsedSDP.videoCodec;
      }

      this.updateStatistic();
    }
  }

  get rtcStatistic(): Subject<IRtcMonitorStatistic> {
    return this._rtcStatistic;
  }

  dispose(): void {
    this._isMonitorRunning = false;
    this._peerConnection = null;

    if (this._updateTimeout) {
      clearTimeout(this._updateTimeout);
      this._updateTimeout = null;
    }
  }

  private async updateStatistic(): Promise<void> {
    if (FeatureEnablement.getStatsPromiseBasedDisabled) {
      const ignored = await this.updateStatisticLegacy();

      return;
    }

    // Safari 11 doesnt have kind or mediaType so we need to take it from the inbound-rtp object id
    const getKindForSafari11 = (item): string => item.id.includes('Audio') ? 'audio' : item.id.includes('Video') ? 'video' : '';
    const ignored = await this._peerConnection.getStats(null)
      .then(stats => {
        const rtcStatistics: IRtcMonitorStatistic = {};

        if (stats) {
          let roundTripTime = this._estimatedRoundTripTime;
          let lastPacketReceivedTimestamp;

          stats.forEach(report => {
            if (report.type === 'candidate-pair' && report.currentRoundTripTime) {
              roundTripTime = report.currentRoundTripTime * 1000;

              if (rtcStatistics['audio']) {
                rtcStatistics['audio'].roundTripTime = roundTripTime;
              }

              if (rtcStatistics['video']) {
                rtcStatistics['video'].roundTripTime = roundTripTime;
              }
            }

            if (report.type === 'candidate-pair' && report.lastPacketReceivedTimestamp) {
              lastPacketReceivedTimestamp = report.lastPacketReceivedTimestamp;

              if (rtcStatistics['audio']) {
                rtcStatistics['audio'].lastPacketReceivedTimestamp = lastPacketReceivedTimestamp;
              }

              if (rtcStatistics['video']) {
                rtcStatistics['video'].lastPacketReceivedTimestamp = lastPacketReceivedTimestamp;
              }
            }

            if (report.type === 'inbound-rtp') {
              const kind = report.kind || report.mediaType || getKindForSafari11(report);
              let codec = '';

              stats.forEach(item => {
                if (item.id === report.codecId) {
                  codec = item.mimeType;
                }
              });

              if (kind && this._tracksToMonitor.includes(kind)) {
                const bytes = report.bytesReceived || 0;
                const previousBytesReceived = this._rtcStatistic.value?.[kind]?.bytesReceived ?? 0;
                const bytesDelta = bytes - previousBytesReceived;
                const timeDelta = report.timestamp - this._rtcStatistic.value?.[kind]?.timestamp;
                const bitrate = bytesDelta && timeDelta ? Math.floor(8 * bytesDelta /
                  timeDelta * 1000) : 0;

                rtcStatistics[kind] = {
                  ssrc: report.ssrc,
                  mediaType: kind,
                  timestamp: report.timestamp,
                  bitrate,
                  bytesReceived: report.bytesReceived,
                  packetsLost: report.packetsLost,
                  packetsReceived: report.packetsReceived,
                  codec: codec || this.getCodecByType(kind),
                  roundTripTime
                };

                if (report.lastPacketReceivedTimestamp || lastPacketReceivedTimestamp) {
                  rtcStatistics[kind].lastPacketReceivedTimestamp = report.lastPacketReceivedTimestamp || lastPacketReceivedTimestamp;
                }

                if (kind === 'video' && this._rtcStatistic.value && this._rtcStatistic.value.video) {
                  rtcStatistics[kind]['framesDecoded'] = report.framesDecoded;

                  const fps = (report.framesDecoded - this._rtcStatistic.value.video.framesDecoded) /
                    (rtcStatistics[kind].timestamp - this._rtcStatistic.value.video.timestamp) *
                    1000;

                  rtcStatistics[kind]['fps'] = fps ? Math.round(fps * 100) / 100 : 0;
                }
              }
            }
          });
        }

        this._rtcStatistic.value = rtcStatistics;
      }).then(() => {
        if (this._isMonitorRunning) {
          this._updateTimeout = window.setTimeout(() => this.updateStatistic(), updateStatisticTimeout);
        }
      });
  }

  private async updateStatisticLegacy(): Promise<void> {
    const ignored = await this._peerConnection.getStatsLegacy().then(stats => {
      const rtcStatistics: IRtcMonitorStatistic = {};
      let roundTripTime = this._estimatedRoundTripTime;

      stats.result().forEach(report => {
        report.names().forEach(name => {
          if (name === 'googRTT') {
            roundTripTime = report.stat(name);
          }
        });

        if (report.type === 'ssrc') {
          const mediaType = report.stat('mediaType').toString();

          rtcStatistics[mediaType] = {timestamp: Date.now()};
          rtcStatistics[mediaType]['ssrc'] = report.stat('ssrc').toString();
          rtcStatistics[mediaType]['mediaType'] = mediaType;
          rtcStatistics[mediaType]['bytesReceived'] = report.stat('bytesReceived');
          rtcStatistics[mediaType]['packetsLost'] = report.stat('packetsLost');
          rtcStatistics[mediaType]['packetsReceived'] = report.stat('packetsReceived');
          rtcStatistics[mediaType]['codec'] = report.stat('googCodecName').toString() || this.getCodecByType(mediaType);
          rtcStatistics[mediaType]['roundTripTime'] = roundTripTime;

          if (mediaType === 'video' && this._rtcStatistic.value && this._rtcStatistic.value.video) {
            rtcStatistics[mediaType]['framesDecoded'] = report.stat('framesDecoded');
            rtcStatistics[mediaType]['fps'] = (rtcStatistics[mediaType].framesDecoded - this._rtcStatistic.value.video.framesDecoded) /
              (rtcStatistics[mediaType].timestamp - this._rtcStatistic.value.video.timestamp) *
            1000 || 0;
          }
        }
      });
      this._rtcStatistic.value = rtcStatistics;
    }).then(() => {
      if (this._isMonitorRunning) {
        this._updateTimeout = window.setTimeout(() => this.updateStatistic(), updateStatisticTimeout);
      }
    });
  }

  private getCodecByType(type: string): string {
    switch (type) {
      case 'audio':
        return this._estimatedAudioCodec;
      case 'video':
        return this._estimatedVideoCodec;
      default:
        return 'unknown';
    }
  }
}