/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Subject from '../../rx/Subject';
import IPeerConnection from '../../rtc/IPeerConnection';

export default class PeerConnectionContext {
  mediaStream: Subject<MediaStream>;
  peerConnection: Subject<IPeerConnection>;
  peerConnectionReconnectAttempts: number;

  constructor() {
    this.mediaStream = new Subject<MediaStream>(null);
    this.peerConnection = new Subject<IPeerConnection>(null);
    this.peerConnectionReconnectAttempts = 0;
  }
}