/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {TelemetryLevel} from './MetricsConfiguration';
import assertUnreachable from '../lang/assertUnreachable';
import MetricsType from './MetricsType';

export default class MetricType {
  private readonly _metrics: MetricsType;

  constructor(metrics: MetricsType) {
    this._metrics = metrics;
  }

  getName(): string {
    return MetricsType[this._metrics];
  }

  getTelemetryLevel(): TelemetryLevel {
    switch (this._metrics) {
      case MetricsType.TimeToFirstFrame:
        return TelemetryLevel.Essential;
      case MetricsType.ChannelCreationTimeToFirstFrame:
        return TelemetryLevel.Essential;
      case MetricsType.Stalled:
        return TelemetryLevel.All;
      case MetricsType.Buffering:
        return TelemetryLevel.All;
      case MetricsType.SetupCompleted:
        return TelemetryLevel.All;
      case MetricsType.SetupFailed:
        return TelemetryLevel.All;
      case MetricsType.RoundTripTime:
        return TelemetryLevel.All;
      case MetricsType.DownlinkThroughputCapacity:
        return TelemetryLevel.All;
      case MetricsType.NetworkType:
        return TelemetryLevel.All;
      case MetricsType.ResolutionChanged:
        return TelemetryLevel.All;
      case MetricsType.ApplicationForeground:
        return TelemetryLevel.All;
      case MetricsType.ApplicationBackground:
        return TelemetryLevel.All;
      default:
        assertUnreachable(this._metrics);
    }
  }
}