/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
export default class Strings {
  static random(length: number): string {
    const random13Characters = (): string => {
      return Math.random().toString(16).substring(2, 15);
    };

    const loops: number = Math.ceil(length / 13);

    return new Array(loops).fill(random13Characters).reduce((string, func) => {
      return string + func();
    }, '').substring(0, length);
  }

  private constructor() {
    throw new Error('Strings is a static class that may not be instantiated');
  }
}