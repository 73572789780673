/**
 * Copyright 2024 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
// As float in ECMAScript use 64-bit format it cannot have/handle more than 15 symbols after dot.
const maxDigitsInFloatAfterDot = 15;

export default class Numbers {
  static countNumberLength(value: number): number {
    let incrementForNegativeValue = 0;
    let number = value;

    if (number < 0) {
      number *= -1;
      incrementForNegativeValue++;
    }

    return Numbers.countDigitsBeforeDecimal(number) + incrementForNegativeValue + (number !== Math.floor(number) ? Numbers.countDigitsAfterDecimal(number) + 1 : 0);
  }

  private static fixRounding(value: number, precision: number): number {
    const power = Math.pow(10, precision);

    return Math.round(value * power) / power;
  }

  private static countDigitsBeforeDecimal(value: number): number {
    let digitsBeforeDecimal = 1;
    let number = value;

    while (number >= 10) {
      number = number / 10;
      digitsBeforeDecimal++;
    }

    return digitsBeforeDecimal;
  }

  private static countDigitsAfterDecimal(value: number): number {
    const originalValue = value;
    let modifiedValue = Math.floor(value);
    let count = 0;

    while (modifiedValue !== originalValue && count < maxDigitsInFloatAfterDot) {
      count++;
      modifiedValue = Numbers.fixRounding(originalValue, count);
    }

    return count;
  }

  private constructor() {
    throw new Error('Numbers is a static class that may not be instantiated');
  }
}